import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { css, Global } from "@emotion/core"
import styled from "@emotion/styled"
import SEO from "../components/seo"
import Header from "../components/header/header"
import { TimelineLite, TweenLite } from "gsap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneVolume } from "@fortawesome/pro-solid-svg-icons"
import Slide1 from "../videos/blume-bg.mp4"
import Slide2 from "../videos/lagnese-bg.mp4"
import Slide3 from "../videos/goodrich-bg.mp4"
import Slide4 from "../videos/blume-bg.mp4"
import Poster1 from "../images/blume-ipad.jpg"
import Poster2 from "../images/berger-ipad.jpg"
import Poster3 from "../images/goodrich-ipad.jpg"
import MobilePoster1 from "../images/blume-mobile.jpg"
import MobilePoster2 from "../images/berger-mobile.jpg"
import MobilePoster3 from "../images/goodrich-mobile.jpg"
import Play from "../images/play.png"
import RevealVideo from "../videos/home-vid.mp4"

const HomeWrapper = styled.section`
  position: relative;
  width: 100vw;
  min-height: 768px;
  height: 100vh;

  @media (min-width: 1024px) {
    overflow: hidden;
  }
`

const BG = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: #10364a;
  transform: translateY(-100%);
`

const VideoBG = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
  transition: all 0.4s ease-in-out;
`

const FloatingText = styled.p`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    color: #fefefe;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 0.875rem;
    writing-mode: vertical-lr;
    white-space: nowrap;

    .sep {
      margin: 0.5rem 0;
      color: #2496c3;
    }
  }
`

const CenterText = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .text {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    transform: translate(1, 0.9);
    text-transform: lowercase;
    color: #fefefe;
    text-align: center;
    margin: 0;
    white-space: nowrap;
  }

  .line-1 {
    text-align: left;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;

    @media (min-width: 640px) {
      margin-bottom: 0;
    }
  }

  .line-2,
  .line-3 {
    font-size: 9vw;
    line-height: 1ch;

    @media (min-width: 1024px) {
      font-size: 6rem;
    }
  }

  .line-3 {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: #fefefe;
    -webkit-text-stroke-width: 1px;
  }

  .reviews {
    background-image: url(${Play});
    background-position: left center;
    background-repeat: no-repeat;
    color: #fefefe;
    text-decoration: none;
    background-color: #1d5f83;
    padding: 2rem 1.5rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 0.875rem;
    letter-spacing: 3px;
    max-width: 400px;
    margin: 4rem auto 0;
    position: relative;
    transition: all 0.4s ease-in-out;
    text-align: center;

    &::before {
      width: 50vw;
      height: 1px;
      background: #fefefe;
      display: block;
      content: "";
      position: absolute;
      right: 95%;
      top: 50%;
    }

    &:hover {
      background-color: #143c52;
    }

    div {
      margin-bottom: 1rem;

      svg {
        margin: 0 0.25rem;
      }
    }
  }
`

const WhiteBanner = styled.div`
  position: absolute;
  min-height: 326px;
  left: 0;
  top: 75%;
  width: 75vw;
  padding: 2rem;
  background-color: #fefefe;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  @media (min-width: 1024px) {
    min-height: 125px;
    width: 90vw;
    top: auto;
    bottom: 0;
    padding: 2rem 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: initial;
  }

  @media (min-width: 1280px) {
    width: 80vw;
    padding: 2rem;
  }

  & > :not(.sticky-cta) {
    order: 1;
  }

  .left {
    min-width: 250px;
    font-family: "Montserrat", sans-serif;

    @media (min-width: 1024px) {
      text-align: right;
      width: 30%;
      max-width: 400px;
    }

    .main {
      margin-top: 0;
    }

    .mobile {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.5;

      @media (min-width: 1024px) {
        display: none;
      }

      span {
        color: #2496c3;
      }

      .innertext {
        color: #0a0a0a;
      }
    }

    p,
    a {
      transform: scale(1, 0.9);
    }

    a {
      font-weight: 700;
      color: #1e1e1e;
    }
  }

  .sep {
    display: none;

    @media (min-width: 1280px) {
      display: block;
      width: 1px;
      height: 6rem;
      margin: 0 2rem 0 4rem;
      background-color: #2496c3;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 2rem;

    @media (min-width: 640px) {
      align-items: flex-start;
      flex-direction: row;
    }

    @media (min-width: 1024px) {
      margin-bottom: 0;
      width: 60%;
      align-items: center;
      justify-content: space-between;
    }

    @media (min-width: 1440px) {
      justify-content: space-around;
    }

    .cs {
      margin: 2rem 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      @media (min-width: 640px) {
        margin: 0 2rem;
      }

      @media (min-width: 1024px) {
        margin: 0 1rem;
      }

      .lead,
      .sublead {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        margin: 0;
        transform: scale(1, 0.9);
      }

      .lead {
        font-size: 2rem;
        line-height: 1ch;

        @media (min-width: 1024px) {
          font-size: 3rem;
        }

        @media (min-width: 1280px) {
          font-size: 4rem;
        }
      }

      .sublead {
        text-transform: uppercase;
        color: #2496c3;
        letter-spacing: 3px;
        font-size: 0.75rem;
        white-space: nowrap;
        margin-top: 0.5rem;

        @media (min-width: 1280px) {
          font-size: 0.875rem;
        }
      }
    }
  }

  .popular {
    display: none;

    @media (min-width: 1024px) {
      display: flex;
      flex-direction: column-reverse;
      background-color: #1d5f83;
      border: none;
      padding: 1.5rem 2rem;
      color: #fefefe;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.75rem;
      letter-spacing: 3px;
      white-space: nowrap;
      position: relative;
      left: 2rem;
      transition: all 0.4s ease-in-out;

      @media (min-width: 1280px) {
        padding: 2rem 3rem;
      }

      &:hover {
        cursor: pointer;
        background-color: #143c52;
      }

      .content {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 100%;
        left: 0;
        width: calc(100% - 2rem);
        padding: 0.5rem 1rem;
        background-color: #1d5f83;
        max-height: 1px;
        opacity: 0;
        transition: all 0.4s ease-in-out;

        &.open {
          & > * {
            pointer-events: all;
          }
        }

        & > * {
          pointer-events: none;
          border: none;
          background: #fefefe;
          padding: 1rem;
          margin: 0.5rem 0;
          color: #0a0a0a;
          text-align: center;
          text-decoration: none;
          opacity: 0;
          transition: all 0.4s ease-in-out;
          box-shadow: 0 0 8px 0 rgba(50, 50, 50, 1);

          &:hover {
            background-color: #2496c3;
            color: #fefefe;
          }
        }
      }
    }

    @media (min-width: 1440px) {
      left: 10vw;
    }
  }
`

const StickyCTA = styled.section`
  @media (min-width: 768px) {
    display: none;
  }

  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 99;
  margin-top: 2rem;
  position: relative;
  right: 2rem;

  & > * {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    color: #fefefe;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.875rem;

    svg {
      margin-right: 1rem;
      transform: rotate(-33deg) scale(1.5);
    }
  }

  & > *:nth-child(1) {
    background: #115e7d;
  }

  & > *:nth-child(2) {
    background-color: #2496c3;
  }
`

const Reveal = styled.div`
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 4rem;
  background: #000c;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &.open {
    opacity: 1;
    pointer-events: all;
  }

  div {
    width: 100%;
  }

  button {
    position: absolute;
    right: 2rem;
    top: 2rem;
    background: none;
    border: none;
    line-height: 1ch;
    font-size: 2rem;
    transform: rotate(45deg);
    transition: all 0.4s ease-in-out;
    color: #fefefe;

    :hover {
      cursor: pointer;
      transform: rotate(0);
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const IndexPage = () => {
  const [currentSlide, setCurrentSlide] = useState(2)

  useEffect(() => {
    if (window.matchMedia("(max-width: 639px)").matches) {
      const cs = document.querySelectorAll(".cs")
      let minW = 0
      cs.forEach(single => {
        if (single.clientWidth > minW) {
          minW = single.clientWidth
        }
      })
      cs.forEach(single => {
        single.style.minWidth = minW + "px"
      })
    }

    if (window.matchMedia("(min-width: 1024px)").matches) {
      const video = document.querySelector("#video-bg")
      video.play()
    }

    const timer = setTimeout(changeContent, 15000)
    return () => {
      clearTimeout(timer)
    }
  })

  function changeContent() {
    const video = document.querySelector("#video-bg")
    const left = document.querySelector(".left")
    const cs1 = document.querySelector(".cs-1")
    const cs2 = document.querySelector(".cs-2")
    const vert = document.querySelector("#vertical")
    if (currentSlide < 3) {
      setCurrentSlide(currentSlide + 1)
    } else {
      setCurrentSlide(1)
    }
    if (video) {
      changeVideo(video)
    }
    if (left) {
      changeLeft(left)
    }
    if (cs1) {
      changeCS1(cs1)
    }
    if (cs2) {
      changeCS2(cs2)
    }
    if (vert) {
      changeVert(vert)
    }
  }

  function changeVideo(video) {
    const bg = document.querySelector("#color-bg")
    let nextSrc
    let poster
    switch (currentSlide) {
      case 1:
        nextSrc = Slide1
        if (window.matchMedia("(min-width: 1024px").matches) {
          poster = Poster1
        } else {
          poster = MobilePoster1
        }
        break
      case 2:
        nextSrc = Slide2
        if (window.matchMedia("(min-width: 1024px").matches) {
          poster = Poster2
        } else {
          poster = MobilePoster2
        }
        break
      case 3:
        nextSrc = Slide3
        if (window.matchMedia("(min-width: 1024px").matches) {
          poster = Poster3
        } else {
          poster = MobilePoster3
        }
        break
      case 4:
        nextSrc = Slide4
        if (window.matchMedia("(min-width: 1024px").matches) {
          poster = Poster1
        } else {
          poster = MobilePoster1
        }
        break
      default:
        break
    }
    TweenLite.to(bg, 0.8, {
      y: 0,
      onComplete: () => {
        setTimeout(() => {
          video.pause()
          video.src = nextSrc
          video.poster = poster
          if (window.matchMedia("(min-width: 1024px").matches) {
            video.play()
          }
          setTimeout(() => {
            let height
            if (window.matchMedia("(min-width: 1024px)").matches) {
              height = window.innerHeight
            } else {
              height = document.body.scrollHeight
            }
            TweenLite.to(bg, 0.8, {
              y: height,
              onComplete: () => {
                TweenLite.set(bg, {
                  y: `-${height}`,
                })
              },
            })
          }, 100)
        }, 2000)
      },
    })
  }

  function changeLeft(left) {
    let lead
    let link
    let linktext
    let mobile
    switch (currentSlide) {
      case 1:
        lead =
          "Blume, Forte, Fried, Zerres + Molinari is New Jersey’s largest personal injury firm"
        mobile =
          "we have been with nextlevel for years and the improvement we’ve seen in the results of our advertising has been nothing short of amazing"
        link = "https://www.njatty.com"
        linktext = link.replace("https://", "")
        break
      case 2:
        lead =
          "Paul Lagnese from Berger & Lagnese is the president elect of the PAJ"
        mobile =
          "we have seen dramatic improvements in performance since moving our website and SEO to the folks at nextlevel"
        link = "https://www.bergerlagnese.com"
        linktext = link.replace("https://", "")
        break
      case 3:
        lead =
          "Josh Geist from Goodrich & Geist is the immediate past president of the PAJ"
        mobile =
          "we just we continue to increase our investment with nextlevel because the increase in business has been so great!"
        link = "https://www.goodrichandgeist.com"
        linktext = link.replace("https://", "")
        break
      case 4:
        lead = "Purchase, George & Murphey"
        mobile =
          "they provide expert advice, great results and a very high level of customer service"
        link = "https://www.yourerielawyers.com"
        linktext = link.replace("https://", "")
        break
      default:
        break
    }
    TweenLite.to(left, 0.4, {
      css: {
        opacity: 0,
      },
    })
    TweenLite.to(left, 0.8, {
      y: 100,
      onComplete: () => {
        left.querySelector(".main").innerText = lead
        left.querySelector(".mobile > .innertext").innerHTML = mobile
        left.querySelector(".link").href = link
        left.querySelector(".link").innerText = linktext
        setTimeout(() => {
          TweenLite.to(left, 0.4, {
            y: 0,
          })
          TweenLite.to(left, 0.4, {
            css: {
              opacity: 1,
            },
          })
        }, 1000)
      },
    })
  }

  function changeCS1(cs1) {
    let lead
    let sublead
    switch (currentSlide) {
      case 1:
        lead = "74%"
        sublead = "increase in traffic"
        break
      case 2:
        lead = "104%"
        sublead = "increase in traffic"
        break
      case 3:
        lead = "229%"
        sublead = "increase in traffic"
        break
      case 4:
        lead = "48%"
        sublead = "increase in traffic"
        break
      default:
        break
    }
    setTimeout(() => {
      TweenLite.to(cs1, 0.4, {
        css: {
          opacity: 0,
        },
      })
      TweenLite.to(cs1, 0.8, {
        y: 100,
        onComplete: () => {
          cs1.querySelector(".lead").innerText = lead
          cs1.querySelector(".sublead").innerText = sublead
          setTimeout(() => {
            TweenLite.to(cs1, 0.4, {
              y: 0,
            })
            TweenLite.to(cs1, 0.4, {
              css: {
                opacity: 1,
              },
            })
          }, 1000)
        },
      })
    }, 250)
  }

  function changeCS2(cs2) {
    let lead
    let sublead
    switch (currentSlide) {
      case 1:
        lead = "52%"
        sublead = "increase in leads"
        break
      case 2:
        lead = "48%"
        sublead = "increase in leads"
        break
      case 3:
        lead = "157%"
        sublead = "increase in leads"
        break
      case 4:
        lead = "to be filled"
        sublead = "pgm"
        break
      default:
        break
    }
    setTimeout(() => {
      TweenLite.to(cs2, 0.4, {
        css: {
          opacity: 0,
        },
      })
      TweenLite.to(cs2, 0.8, {
        y: 100,
        onComplete: () => {
          cs2.querySelector(".lead").innerText = lead
          cs2.querySelector(".sublead").innerText = sublead
          setTimeout(() => {
            TweenLite.to(cs2, 0.4, {
              y: 0,
            })
            TweenLite.to(cs2, 0.4, {
              css: {
                opacity: 1,
              },
            })
          }, 1000)
        },
      })
    }, 500)
  }

  function changeVert(vert) {
    let l1
    let l2
    switch (currentSlide) {
      case 1:
        l1 = "blume, forte, fried, zerres & molinari"
        l2 = "njatty.com"
        break
      case 2:
        l1 = "berger & lagnese"
        l2 = "bergerlagnese.com"
        break
      case 3:
        l1 = "goodrich & geist"
        l2 = "goodrichandgeist.com"
        break
      case 4:
        l1 = "purchase, george & murphey"
        l2 = "yourerielawyers.com"
        break
      default:
        break
    }
    setTimeout(() => {
      TweenLite.to(vert, 0.4, {
        css: {
          opacity: 0,
        },
      })
      TweenLite.to(vert, 0.8, {
        x: 100,
        onComplete: () => {
          vert.querySelector(".l1").innerText = l1
          vert.querySelector(".l2").innerText = l2
          setTimeout(() => {
            TweenLite.to(vert, 0.4, {
              x: 0,
            })
            TweenLite.to(vert, 0.4, {
              css: {
                opacity: 1,
              },
            })
          }, 1000)
        },
      })
    }, 750)
  }

  function togglePopular() {
    const tl = new TimelineLite()
    const content = document.querySelector(".content")
    if (content.classList.contains("open")) {
      content.classList.remove("open")
      content.querySelectorAll("*").forEach(button => {
        tl.to(button, 0.1, { css: { opacity: 0 } }, "+=0.1")
      })
      tl.to(content, 0.1, { css: { maxHeight: "0px", opacity: 0 } })
    } else {
      content.classList.add("open")
      tl.to(content, 0.1, { css: { maxHeight: "300px", opacity: 1 } })
      content.querySelectorAll("*").forEach(button => {
        tl.to(button, 0.1, { css: { opacity: 1 } }, "+=0.1")
      })
    }
  }

  function reviewsButton(e) {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      e.preventDefault()
      openReveal()
    }
  }

  function openReveal() {
    const reveal = document.querySelector(".reveal")
    reveal.classList.add("open")
    reveal.querySelector("video").play()
  }

  function closeReveal() {
    const reveal = document.querySelector(".reveal")
    reveal.querySelector("video").pause()
    reveal.classList.remove("open")
  }

  return (
    <>
      <SEO title="Websites & Digital Marketing for Lawyers" />
      <Header />
      <Global
        styles={css`
          body {
            background-color: #fefefe !important;
          }
        `}
      />
      <Reveal className="reveal">
        <video loop muted playsInline>
          <source src={RevealVideo} type="video/mp4" />
        </video>
        <button onClick={closeReveal}>&times;</button>
      </Reveal>
      <HomeWrapper>
        <BG id="color-bg" />
        <VideoBG
          src={Slide1}
          poster={Poster1}
          loop
          muted
          id="video-bg"
          playsInline
        />
        <FloatingText id="vertical">
          <span className="l1">blume, forte, fried, zerres & molinari</span>
          <span className="sep">|</span>
          <span className="l2">njatty.com</span>
        </FloatingText>
        <CenterText>
          <p className="text line-1">digital marketing for lawyers</p>
          <p className="text line-2">we generate leads</p>
          <p className="text line-3">you practice law</p>
          <Link
            className="reviews"
            to="/reviews"
            onClick={e => reviewsButton(e)}
          >
            What our clients are saying
          </Link>
        </CenterText>
        <WhiteBanner id="whitebanner">
          <div className="left">
            <p className="main">
              Blume, Forte, Fried, Zerres + Molinari is New Jersey’s largest
              personal injury firm
            </p>
            <p className="mobile">
              <span>“</span>
              <span className="innertext">
                we have been with nextlevel for years and the improvement we’ve
                seen in the results of our advertising has been nothing short of
                amazing
              </span>
              <span>”</span>
            </p>
            <a
              href="https://www.njatty.com"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              www.njatty.com
            </a>
          </div>
          <div className="sep"></div>
          <div className="right">
            <div className="cs cs-1">
              <p className="lead">74%</p>
              <p className="sublead">increase in traffic</p>
            </div>
            <div className="cs cs-2">
              <p className="lead">52%</p>
              <p className="sublead">increase in leads</p>
            </div>
          </div>
          <div className="popular" onClick={togglePopular}>
            Our most popular content
            <div className="content">
              <Link to="/portfolio">Our Work</Link>
              <Link to="/all-services">Our Services</Link>
              <Link to="/reviews">Client Reviews</Link>
              <Link to="/contact">Request Pricing</Link>
            </div>
          </div>
          <StickyCTA className="sticky-cta">
            <Link to="/contact">Request Pricing</Link>
            <a href="tel:8665321187">
              <FontAwesomeIcon icon={faPhoneVolume} />
              Call Now
            </a>
          </StickyCTA>
        </WhiteBanner>
      </HomeWrapper>
    </>
  )
}

export default IndexPage
